import React from "react"
import Box from "@mui/material/Box"
import SEO from "../components/SEO"
import { navigate, PageProps } from "gatsby"
import Button from "../components/design-system/Button"
import ButtonWrapper from "../components/ButtonWrapper"
import ConfidentialityAgreementText from "../components/ConfidentialityAgreementText"

const title = "Confidentiality"

const ConfidentialityPage: React.FC<PageProps> = () => (
  <Box sx={{ maxWidth: 650 }}>
    <SEO title={title} />
    <ConfidentialityAgreementText />
    <ButtonWrapper>
      <Button role="link" color="secondary" onClick={() => navigate(-1)}>
        Back
      </Button>
    </ButtonWrapper>
  </Box>
)

export default ConfidentialityPage
