import { ReactElement } from "react"
import T from "@mui/material/Typography"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import React from "react"
import { styled } from "@mui/material/styles"
import Box from "@mui/material/Box"

export const TextWrapper = styled(Box)(() => ({
  maxWidth: 600,
}))

interface ConfidentialityAgreementProps {
  firstSentence?: boolean
}

const ConfidentialityAgreementText = (
  props: ConfidentialityAgreementProps
): ReactElement<ConfidentialityAgreementProps> => {
  return (
    <>
      <T variant="h1" component="h1" sx={{ mb: 0 }}>
        Confidentiality
      </T>
      <T variant="body2" sx={{ mb: "40px" }}>
        Last updated 26 May 2023
      </T>
      <TextWrapper>
        {props.firstSentence && (
          <T variant="body2">
            You will compromise our admission standards if you share details of
            your admissions assessment or interview.
          </T>
        )}
        <T variant="body2">
          You are required to maintain confidentiality at all times. This
          includes the admissions process, the interview and if you are
          admitted, whilst you are a student at Cambridge.
        </T>
        <T variant="body2">
          You must not disclose details of any admissions assessment, assigned
          pre-reading or interview questions to:
        </T>
        <List component="ul" className="MuiList-unordered">
          <ListItem>
            <T variant="body2">other applicants</T>
          </ListItem>
          <ListItem>
            <T variant="body2">any commercial organisation</T>
          </ListItem>
        </List>
        <T variant="body2">
          This includes, but is not limited to, communication indirectly or
          directly by:
        </T>
        <List component="ul" className="MuiList-unordered">
          <ListItem>
            <T variant="body2">email</T>
          </ListItem>
          <ListItem>
            <T variant="body2">websites</T>
          </ListItem>
          <ListItem>
            <T variant="body2">chat rooms</T>
          </ListItem>
          <ListItem>
            <T variant="body2">texts with other applicants</T>
          </ListItem>
        </List>
        <T variant="body2">
          You or any third party are strictly forbidden to record or photograph
          any part of the application process. We will cancel your application
          to the University if you:
        </T>
        <List component="ul" className="MuiList-unordered">
          <ListItem>
            <T variant="body2">record your assessment or interview</T>
          </ListItem>
          <ListItem>
            <T variant="body2">photograph your assessment or interview</T>
          </ListItem>
          <ListItem>
            <T variant="body2">
              have an unauthorised third party present during your assessment or
              interview
            </T>
          </ListItem>
        </List>
        <T variant="body2">
          We understand that you may wish to discuss your interviews with
          advisers at your school or college. However, we ask that you do not
          share full details of any specific questions we ask.
        </T>
        <T variant="body2">
          The duty of confidentiality does not apply to any disclosures required
          by law or for the purposes of seeking legal advice.
        </T>
      </TextWrapper>
    </>
  )
}

export default ConfidentialityAgreementText
